import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {AccountExperienceTransaction, GetOverwolfXpSubjectEnum, OWGame} from "@devour/client";
import {FiX} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";
import {AnchorHTMLAttributes, ReactElement, useState} from "react";
import useThemePreference from "@/hooks/useThemePreference";
import {RewardsView} from "@/pages/overwolf/components/OverwolfGoVipRewards";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import OverwolfGoVipTimeRewards from "@/pages/overwolf/components/OverwolfGoVipTimeRewards";
import OverwolfGoVipQuestRewards from "@/pages/overwolf/components/OverwolfGoVipQuestRewards";
import useGetOverwolfGameInfo from "@/hooks/useGetOverwolfGameInfo";
import useGetOverwolfGameRewards from "@/hooks/useGetOverwolfGameRewards";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import Skeleton from "react-loading-skeleton";
import useGetOverwolfUserStats from "@/hooks/useGetOverwolfUserStats";
import {isDesktop} from "react-device-detect";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    rewards: Array<AccountExperienceTransaction>;
    game: OWGame;
}

// const testGameData = {
//     League: {
//         gameId: "5426",
//         gameTitle: "League of Legends",
//         isGameRunning: true,
//     },
//     Fortnite: {
//         gameId: "21216",
//         gameTitle: "Fortnite",
//         isGameRunning: true,
//     },
// };

export default function GoVipOWDualRewardModal(props: Props) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {isOnDarkMode} = useThemePreference();
    const [rewardsView, setRewardsView] = useState<RewardsView>(RewardsView.TIME_PLAYED);
    const overwolfXPSubject = rewardsView === RewardsView.QUESTS ? GetOverwolfXpSubjectEnum.EVENTREWARD : GetOverwolfXpSubjectEnum.TIMEREWARD;
    const gameInfo = props.game;

    const {
        data: overwolfGameInfo,
        isLoading: isOverwolfGameInfoLoading,
    } = useGetOverwolfGameInfo(gameInfo?.gameId);

    const {
        data: overwolfGameRewards,
        isLoading: isOverwolfGameRewardsLoading,
    } = useGetOverwolfGameRewards(gameInfo?.gameId, overwolfGameInfo?.isEventSupported ?? false);

    const {
        data: rewardsData,
        isLoading: isRewardsDataInitialLoading,
    } = useGetUserOverwolfXP(fullToken, overwolfXPSubject);

    const {data: userStats, refetch: refetchUserStats, isLoading: isUserStatsLoading} = useGetOverwolfUserStats(fullToken, overwolfGameInfo?.gameId);
    useOverwolfInterop((ev) => {
        if (ev.type === "ow:game-events-logged") {
            refetchUserStats();
        }
    });
    console.log("gameInfo: ", overwolfGameInfo);
    console.log("userStats data: ", userStats);

    const renderSkeleton = () =>
        <div className="overwolf-govip_rewards_time_list">
            {Array.from({length: 5}, (_, i) =>
                <div key={i} className="go-vip-ow_time-reward-card">
                    <div>
                        <p><Skeleton width={150}/></p>
                        <span><Skeleton width={110}/></span>
                    </div>
                    <span><Skeleton width={110}/></span>
                </div>)}
        </div>;

    function renderRewardsContent(): ReactElement {
        return isRewardsDataInitialLoading || isOverwolfGameInfoLoading || isOverwolfGameRewardsLoading || isUserStatsLoading
            ? renderSkeleton()
            : <>
                {rewardsView === RewardsView.TIME_PLAYED &&
                    <OverwolfGoVipTimeRewards
                        rewards={rewardsData?.transactions || []}
                    />
                }

                {rewardsView === RewardsView.QUESTS &&
                    <OverwolfGoVipQuestRewards
                        userStats={userStats?.userStats || []}
                        gameId={gameInfo?.gameId}
                        quests={overwolfGameRewards || []}
                        rewards={rewardsData?.transactions || []}
                    />
                }

                {rewardsView === RewardsView.QUESTS && userStats?.userStats.length === 0 && <FrameButton
                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                    size="normal"
                    className="go-vip-ow-dual-rewards-modal_body_download-btn"
                    href={"https://www.overwolf.com/app/devourgo"} // TODO: Add correct link
                    forwardProps={{
                        target: "_blank",
                        rel: "noopener noreferrer",
                    }}
                >
                    Download DevourGO+
                </FrameButton>
                }
            </>;
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName={classNames("go-vip-ow_rewards-modal", {
                "authenticated": !!fullToken,
            })}
            size="xs2"
            modalOnTablet={true}
            fullScreenHeight={!!fullToken}
        >
            <div
                style={{
                    backgroundImage: `url(${overwolfGameInfo?.bannerImage?.small})`,
                }}
                className="go-vip-ow-dual-rewards-modal_header"
            >
                <button className="reset-button go-vip-ow_rewards-modal_header_close">
                    <FiX
                        size={isOnDarkMode ? "2rem" : "1.5rem"}
                        strokeWidth="0.175rem"
                        onClick={props.onClose}
                    />
                </button>
                <div className="go-vip-ow-dual-rewards-modal_header_text">
                    <img src={overwolfGameInfo?.devourGameLogo} alt="DevourGO logo"
                        className={"go-vip-ow-dual-rewards-modal_logo"}/>
                    <h1>{isDesktop
                        ? "TRACK REWARDS"
                        : <>TRACK<br />REWARDS</>
                    }</h1>
                </div>

                <FrameOneSwitchInput
                    <RewardsView>
                    className="go-vip-ow-dual-rewards-modal_header_switch"
                    name="overwolf-rewards-view-toggle"
                    value={rewardsView}
                    onToggle={setRewardsView}
                    options={[
                        {
                            render: "Quests",
                            value: RewardsView.QUESTS,
                        },
                        {
                            render: "Time Played",
                            value: RewardsView.TIME_PLAYED,
                        },
                    ]}
                />

                {rewardsView === RewardsView.TIME_PLAYED &&
                    <div className="go-vip-ow-dual-rewards-modal_header_time-text">
                        <div className="go-vip-ow-dual-rewards-modal_header_text_description">
                            Earn 10 XP for every hour of gameplay!<br/>The more you play, the more XP you collect.
                        </div>

                        <div className="go-vip-ow-dual-rewards-modal_header_text_disclaimer">
                            * Max XP you can earn is 1,000 per week.
                        </div>
                    </div>
                }
            </div>

            <FrameAutoPanelBody className="go-vip-ow_rewards-modal_body go-vip-ow-dual-rewards-modal_body">
                {renderRewardsContent()}
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}
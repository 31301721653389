import useGetOverwolfArt, { OverwolfArtType } from "@/hooks/useGetOverwolfArt";
import { ReactComponent as GameLogoDivider } from "../../../svgs/overwolf-game-logo-divider.svg";
const OverwolfGameLogo = () => {
    const { getOverwolfArt, hasCustomLogo } = useGetOverwolfArt();

    if (hasCustomLogo) {
        return (
            <div className="overwolf-game-logo">
                <img className="overwolf-game-logo_left" src={getOverwolfArt(OverwolfArtType.devourLogo)} alt="Devour logo left" />
                <GameLogoDivider />
                <img className="overwolf-game-logo_right" src={getOverwolfArt(OverwolfArtType.logo)} alt="Devour logo right" />
            </div>
        );
    }

    return <img src={getOverwolfArt(OverwolfArtType.devourLogo)} alt="Devour logo" />;
};


export default OverwolfGameLogo;

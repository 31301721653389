import {OverwolfApi, OWReward, OWUserStat} from "@devour/client";
import classNames from "classnames";
import {RiVipDiamondLine} from "react-icons/ri";
import CircularProgressIndicator from "../CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "../buttons/FrameButton";
import {MdCheck, MdLock} from "react-icons/md";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import getConfig from "@/utils/getConfig";
import useGetOverwolfUserStats from "@/hooks/useGetOverwolfUserStats";
import Toast from "@/components/Toast";
import {CSSProperties, Dispatch, SetStateAction, useState} from "react";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {store} from "@/redux";
import {updateAccountLevel, updateCurrentUser} from "@/redux/meta/metaActions";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";

interface Props {
    quest: OWReward;
    userStat?: OWUserStat;
    className?: string;
    gameId: string;
    isClaimingInProgress: boolean;
    setIsClaimingInProgress: Dispatch<SetStateAction<boolean>>;
}

export default function GoVipOWQuestRewardCard(props: Props) {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [claimingToast, setClaimingToast] = useState<{ message: string, success: boolean }>(undefined);
    const {refetch: refetchUserStats} = useGetOverwolfUserStats(fullToken, props.gameId, false);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken, true);
    const {refetch: refetchUserLevel} = useGetUserLevel(fullToken, store.getState().metaStore.currentUser?.user.id, true);
    const isOnMobile = isMobile && !isTablet;
    const isOnOverwolf = store.getState().metaStore.isOnOverwolf;

    async function claimOverwolfReward() {
        props.setIsClaimingInProgress(true);
        try {
            await new OverwolfApi(getConfig(fullToken)).claimOverwolfReward({rewardId: props.quest.id});
            await refetchUserStats();
            setClaimingToast({
                message: `${props.quest.rewardAmount} ${props.quest.rewardType} successfully claimed!`,
                success: true,
            });
            void updateUserLevel();
        } catch (e) {
            setClaimingToast({
                message: "There has been a problem claiming your reward. Please try again later!",
                success: false,
            });
        } finally {
            props.setIsClaimingInProgress(false);
        }
    }

    async function updateUserLevel() {
        const [
            updatedUserProfile,
            updatedUserLevel,
        ] = await Promise.all([refetchUserProfile(), refetchUserLevel()]);
        if (updatedUserProfile.data) {
            dispatch(updateCurrentUser(updatedUserProfile.data));
        }

        if (updatedUserLevel.data) {
            dispatch(updateAccountLevel(updatedUserLevel.data));
        }
    }

    const getCurrentProgress = () => {
        if (!props.userStat) {
            return 0;
        }
        if (props.userStat.progress < props.quest.target) {
            return props.userStat.progress;
        }
        return props.quest.target;
    };

    const renderClaimButton = () => {
        if (!props.userStat || props.userStat.progress < props.quest.target) {
            return null;
        }

        if (props.userStat?.isClaimed) {
            return (
                <FrameButton size="narrow" color="gray" className={classNames("go-vip-ow_quest-reward-card-btn", {
                    "full-width": !isOnOverwolf && isMobile,
                })}>
                    Claimed {isOnOverwolf && <MdCheck/>}
                </FrameButton>
            );
        }

        return (
            <FrameButton
                size="narrow"
                onClick={claimOverwolfReward}
                color="purple-blue-gradient-shadow"
                className={classNames("", {
                    "go-vip-ow_quest-reward-card_claim-btn": !isOnOverwolf,
                    "full-width": !isOnOverwolf && isMobile,
                })}
                forwardProps={{
                    disabled: props.isClaimingInProgress,
                }}
            >
                Claim
            </FrameButton>
        );
    };

    const getCircularProgressGlow = () => {
        return props.userStat && props.userStat.progress >= props.quest.target && !props.userStat.isClaimed;
    };

    const renderCompletedAt = () => {
        if (!props.userStat || !props.userStat.isClaimed) {
            return null;
        }

        const completedDate: string = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "numeric",
        }).format(props.userStat.updatedAt);

        return <span className="go-vip-ow_quest-reward-card_completed-at">
            Completed on {completedDate}
        </span>;
    };

    function getToastStyle(): CSSProperties {
        if (isOnMobile && isOnOverwolf) {
            return {
                maxWidth: "100vw",
                width: "100vw",
                borderRadius: "0",
                marginBottom: "0",
                marginLeft: "0",
            };
        }

        return {
            marginBottom: "0",
        };
    }

    return (
        <div className={!isOnOverwolf && "go-vip-ow_quest-reward-card-column"}>
            {!isOnOverwolf && (isDesktop || isMobile) && renderCompletedAt()}
            <div
                className={classNames("go-vip-ow_quest-reward-card", props.className, {
                    disabled: !fullToken,
                    "go-vip-ow_quest-reward-card_govip": !isOnOverwolf,
                })}
            >
                <Toast
                    message={claimingToast?.message}
                    isOpen={!!claimingToast}
                    onDismiss={() => setClaimingToast(undefined)}
                    removeMarginAdjustment={true}
                    className={classNames(
                        "overwolf-govip_rewards_claim",
                        {"overwolf-govip_rewards_claim_in-game": isMobile},
                    )}
                    forceDarkMode={true}
                    variant={claimingToast?.success ? "success" : "error"}
                    style={isMobile && {
                        maxWidth: "100vw",
                        width: "100vw",
                        borderRadius: "0",
                        marginBottom: "0",
                        marginLeft: "0",
                    }}
                />
                {isOnOverwolf && isMobile && !isTablet && renderCompletedAt()}
                <div className="go-vip-ow_quest-reward-card_info">
                    <CircularProgressIndicator
                        showGlow={getCircularProgressGlow()}
                        total={props.quest.target}
                        current={getCurrentProgress()}
                    />
                    <div className="go-vip-ow_quest-reward-card_info_content">
                        <div>
                            <strong className="go-vip-ow_quest-reward-card_info_content_title">
                                {props.quest.name} {isDesktop && isOnOverwolf && renderCompletedAt()}
                            </strong>
                            <p className="go-vip-ow_quest-reward-card_info_content_description">
                                {props.quest.description}
                            </p>
                        </div>
                        <strong className={classNames(
                            "go-vip-ow_quest-reward-card_info_content_points",
                            {"no-user-stats": !props.userStat},
                        )}
                        >
                            <RiVipDiamondLine className={!props.userStat && "no-user-stats"}/> {props.quest.rewardAmount} {props.quest.rewardType}
                        </strong>
                    </div>
                </div>
                <div className={classNames(
                    "go-vip-ow_quest-reward-card_action",
                    {"auto-width": !isOnOverwolf},
                )}>
                    {/* For GoVIP page */}
                    {!isOnOverwolf &&
                        <>
                            {isDesktop && fullToken && renderClaimButton()}
                            {!props.userStat && <MdLock size={"1.5rem"} className="go-vip-ow_quest-reward-card_lock"/>}
                        </>
                    }

                    {/* For Overwolf GoVIP Page */}
                    {isOnOverwolf &&
                        <>
                            {fullToken ? renderClaimButton() : <MdLock className="go-vip-ow_quest-reward-card_action_blocked"/>}
                        </>
                    }
                </div>
            </div>
            {!isOnOverwolf && isMobile && fullToken && renderClaimButton()}
        </div>
    );
}

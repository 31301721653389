import {ReactElement, useEffect, useState} from "react";
import GoVipHeader from "../components/goVip/GoVipHeader";
import GoVipEarnPointsCard from "../components/goVip/GoVipEarnPointsCard";
import GoVipShare2Earn from "../components/goVip/GoVipShare2Earn";
import GoVipPlay2Earn from "../components/goVip/GoVipPlay2Earn";
import {isMobile, isTablet} from "react-device-detect";
import GoVipSpin2Win from "../components/goVip/GoVipSpin2Win";
import Divider from "@/components/Divider";
import GoVipRewardsBanner from "@/components/goVip/GoVipRewardsBanner";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {addError, updateAccountLevel, updateCurrentUser} from "@/redux/meta/metaActions";
import { useOnLogin } from "@/hooks/useOnLogin";
import getAnonymousSpin from "@/utils/getAnonymousSpin";
import Toast from "@/components/Toast";
import DevourTopNav from "@/components/DevourTopNav";
import GoVipOWEarnPoints from "@/components/goVip/GoVipOWEarnPoints";
import GoVipOWTimeBasedGames from "@/components/goVip/GoVipOWTimeBasedGames";
import {useGate} from "statsig-react";
import GoVipOWDualRewardingGames from "@/components/goVip/GoVipOWDualRewardingGames";

function GoVip(): ReactElement {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [anonymousSpinMessage, setAnonymousSpinMessage] = useState<string | null>(null);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const {data: userLevel} = useGetUserLevel(fullToken, currentUser?.user?.id);
    const {value: overwolfEnabled} = useGate(import.meta.env.VITE_ENABLE_OVERWOLF_ON_GOVIP);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userProfileData && userLevel) {
            void getUpdatedUserProfile();
            void updateLevelData();
        }
    }, [
        userProfileData,
        userLevel,
    ]);

    useOnLogin((newUser) => {
        const anonymousSpin = getAnonymousSpin();
        if (anonymousSpin) {
            if (newUser) {
                setAnonymousSpinMessage(`Congratulations! You have won ${anonymousSpin.spinValue} from the daily spin!`);
            } else {
                setAnonymousSpinMessage("This spin was for new accounts only");
            }
        }
    });

    async function updateLevelData() {
        try {
            dispatch(updateAccountLevel(userLevel));
        } catch (err) {
            dispatch(await addError(err));
        }
    }

    async function getUpdatedUserProfile(): Promise<void> {
        try {
            dispatch(updateCurrentUser(userProfileData));
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    return (
        <div className="go-vip">
            <Toast
                duration={4000}
                message={anonymousSpinMessage}
                isOpen={!!anonymousSpinMessage}
                showButton={false}
                onDismiss={() => setAnonymousSpinMessage(null)}
            />
            <DevourTopNav
                text="GoVIP"
            />
            <GoVipHeader/>

            <div className="go-vip_top go-vip_padding-adjust">
                <GoVipEarnPointsCard/>
            </div>

            <GoVipShare2Earn/>

            {/* {overwolfEnabled && <div className="go-vip-overwolf go-vip_padding-adjust">*/}
            {/*    <GoVipOWEarnPoints />*/}

            {/*    <GoVipOWTimeBasedGames />*/}

            {/*    <GoVipOWDualRewardingGames />*/}
            {/* </div>}*/}

            <div className="go-vip-overwolf go-vip_padding-adjust">
                <GoVipOWEarnPoints />

                <GoVipOWTimeBasedGames />

                <GoVipOWDualRewardingGames />
            </div>

            {isMobile && !isTablet && <Divider />}

            <GoVipPlay2Earn/>

            <GoVipRewardsBanner />

            <GoVipSpin2Win/>

        </div>
    );
}

export default GoVip;
